import React, { useState } from 'react'
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material'
import { fileTypesToAcceptString } from './utils'
import { FileUploadReducer, createFileUploaderReducer, createFileUploaderValidation, useFileUploader } from './useFileUploader'
import { AcceptedFileTypes } from './types'
import { useTranslation } from 'react-i18next'
import { useHumanReadableFilesize } from './use-human-readable-filesize'
import { FileUploaderBase } from './FileUploaderBase'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'

export interface Props
  extends Partial<Pick<React.ComponentProps<typeof FileUploaderBase>, 'PaperComponent' | 'multiple' | 'accept' | 'maximumFilesize'>> {
  /**
   * This prop is invoked when files state changes, e.g. on selecting a file.
   */
  onChange?: (files: File[], callback: (files: File[]) => void) => void
  /**
   * This prop can be provided to alter behavior of state change.
   */
  reducer?: FileUploadReducer
  onDelete: () => void
  imageUrl?: string
}

const useStyles = makeStyles(() => ({
  buttonStyle: {
    backgroundColor: '#616161',
    width: '75px',
    height: '22px',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    position: 'absolute',
    top: '100%'
  },
  deleteIconStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '200px',
    position: 'relative'
  }
}))

export const ImageUploadButton: React.FC<Props> = ({
  multiple = true,
  maximumFilesize = 5,
  accept = ['jpg', 'jpeg', 'png', 'svg'] as AcceptedFileTypes[],
  reducer = createFileUploaderReducer(createFileUploaderValidation(multiple, accept, maximumFilesize)),
  onChange,
  onDelete,
  imageUrl
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const formatter = useHumanReadableFilesize()
  const classes = useStyles()
  const { files, setFiles, appendFiles, error } = useFileUploader(reducer)

  const inputRef = React.useRef<HTMLInputElement>(null)
  const [showDeleteButton, setShowDeleteButton] = useState(false)

  function updateFiles(fileList: FileList) {
    const files = Array.from(fileList)
    appendFiles(files)
  }

  React.useEffect(() => {
    if (files.length === 0) {
      inputRef.current && (inputRef.current.value = '')
    }
    onChange && onChange(files, setFiles)
  }, [files])

  return (
    <Box sx={{ mt: 2, textAlign: 'left' }}>
      <Box display='flex' alignItems='flexstart'>
        <Typography sx={{ fontSize: '1rem', fontWeight: '600', paddingTop: 1 }}>{t('setting_employee.profile_picture')}</Typography>
        {(files.length > 0 || imageUrl) && (
          <Box className={classes.deleteIconStyle}>
            <IconButton
              data-testid='delete-icon'
              onMouseEnter={() => setShowDeleteButton(true)}
              onMouseLeave={() => setShowDeleteButton(false)}
              onClick={() => {
                setFiles([])
                onDelete()
              }}
            >
              <DeleteIcon sx={{ fontSize: 24, color: theme.palette.primary.main }} />
            </IconButton>
            {showDeleteButton && (
              <Button variant='contained' className={classes.buttonStyle}>
                {t('setting_employee.delete_avatar')}
              </Button>
            )}
          </Box>
        )}
      </Box>

      {error && (
        <Box mt={2} textAlign='left'>
          <Typography variant='body2' fontSize='0.85rem' color={theme.palette.error.main}>
            {t(error)}
          </Typography>
        </Box>
      )}

      {files.length === 0 && !imageUrl && (
        <>
          <Box mt={1} textAlign='left' sx={{ pb: 1 }}>
            <Typography variant='body2' color='textSecondary'>
              {t('FileUploader.AcceptedFormats')}
              {fileTypesToAcceptString(accept)}.<br />
              {t('FileUploader.Max_size')}&nbsp;{formatter(maximumFilesize)}.
            </Typography>
          </Box>

          <Button variant='outlined' color='primary' onClick={() => inputRef.current && inputRef.current.click()}>
            {t('FileUploader.SelectImage')}
            <input
              data-testid='file-upload-input'
              ref={inputRef}
              style={{ display: 'none' }}
              accept={fileTypesToAcceptString(accept)}
              multiple={multiple}
              type='file'
              onChange={event => {
                if (event.target.files && event.target.files.length > 0) {
                  updateFiles(event.target.files)
                }
              }}
            />
          </Button>
        </>
      )}
    </Box>
  )
}
